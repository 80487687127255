import {ScrollShadow, Link} from "@nextui-org/react";
import {FaLongArrowAltRight, FaAngleRight, FaAngleLeft} from "react-icons/fa";
import {Carousel, IconButton} from "@material-tailwind/react";
import Image from "next/image";
import {NEWS_TYPE} from '@/components/helpers/constant.js';
import { CategoryHelper } from '@/components/helpers/category';

import dynamic from "next/dynamic";
const DioceseNewsBlock = dynamic(() => import("@/components/shared/diocese-news-blocks"), {
    ssr: false
});
import logo from '../../public/webp/logo-for-web.webp';
import SharedService from "@/services/shared-service";

var _ = require('lodash');

export default function DioceseNews(dataNews) {
    const dioceseNews = dataNews.data;
    const dioceseNewsFirst = dioceseNews.value ? _.head(dioceseNews.value) : '';
    const dioceseNewsFuther = dioceseNews.value ? _.drop(dioceseNews.value) : '';
    const currentCategory = CategoryHelper.getCurrentCategory(NEWS_TYPE.DIOCESE.code);
    const dataTiktok = dataNews.dataTiktok;

    if (!currentCategory) {
        return <></>
    }

    const cateDetailLink = currentCategory.level === 0
        ? `/chuyen-muc/${currentCategory.code}`
        : `/chuyen-de/${currentCategory.code}`;

    return (
        <div className="diocese-news">
            {dioceseNewsFirst && (
                <div
                    className="rounded-t-[5px] xl:grid grid-cols-3 py-10 !bg-background/80 dark:bg-background/20 !bg-white/85 !bg-cover !bg-[url('/webp/chanh-toa.webp')] !bg-blend-lighten bg-repeat-y bg-center">
                    <div key="logo" className="mx-5 xl:mx-0 pb-5 xl:pb-0 xl:ml-10 text-center xl:text-left">
                        <Image
                            alt="Card background"
                            className="rounded-none mx-auto inline-block"
                            src={logo}
                            width={100}
                            height={100}
                            sizes="(max-width: 768px) 100vw,
                              (max-width: 1200px) 50vw,
                              33vw"
                        />
                        <Link href={cateDetailLink}>
                            <h2 className="font-bold text-2xl text-primary inline-block ml-3">{currentCategory?.name.toLocaleUpperCase()}</h2>
                        </Link>
                    </div>

                    <div key="title" className="mx-5 xl:mx-0">
                        <h1 className="font-bold text-xl text-primary">{dioceseNewsFirst.title}</h1>

                        <p className="my-5 text-lg italic">{dioceseNewsFirst.metaDescription}</p>

                        <Link
                            className="text-md font-bold text-link" color="" size="sm"
                            href={SharedService.getPostPath(dioceseNewsFirst)}
                        >
                            Xem thêm <FaLongArrowAltRight className="top-header-icon !text-link !fill-link ml-2"/>
                        </Link>
                    </div>

                    {/* Only apply Desktop */}
                    {dataTiktok?.length && _.head(dataTiktok)[0]?.link && <>
                        <div className="hidden lg:flex justify-center pt-12 xl:pt-0">
                            <iframe
                                scrolling="no"
                                allowFullScreen
                                src={`https://www.tiktok.com/embed/${_.head(dataTiktok)[0].link}`}
                                className="border-none overflow-hidden h-[560px] w-[315px]"
                            ></iframe>
                        </div>
                    </>}
                </div>
            )}

            {/* Desktop layout: start */}
            <ScrollShadow orientation="vertical" className="md:flex hidden bg-primary rounded-b-[5px]">
                <div className="diocese-news-more py-3 md:p-5 md:flex justify-center mx-auto hidden gap-5">
                    {dioceseNewsFuther && dioceseNewsFuther.map(newsItem => {
                        return <>
                            <DioceseNewsBlock key={newsItem.id} data={newsItem}/>
                        </>
                    })}
                </div>
            </ScrollShadow>
            {/* Desktop layout: end */}

            {/* Mobile layout: start */}
            <div className="bg-primary md:hidden mx-auto rounded-b-[5px]">
                <Carousel
                    loop={true}
                    autoplay={true}
                    autoplayDelay={5000}
                    className="rounded-xl overflow-hidden"
                    prevArrow={({handlePrev}) => (
                        <IconButton
                            aria-label="button-prev"
                            variant="text"
                            size="md"
                            onClick={handlePrev}
                            className="rounded-full bg-gray-600/40 !absolute top-1/4 left-4 -translate-y-2/4"
                        >
                            <FaAngleLeft className="fill-white text-3xl"/>
                        </IconButton>
                    )}
                    nextArrow={({handleNext}) => (
                        <IconButton
                            aria-label="button-next"
                            variant="text"
                            size="md"
                            onClick={handleNext}
                            className="rounded-full bg-gray-600/40 !absolute top-1/4 !right-4 -translate-y-2/4"
                        >
                            <FaAngleRight className="fill-white text-3xl"/>
                        </IconButton>
                    )}
                    navigation={({setActiveIndex, activeIndex, length}) => (
                        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                            {new Array(length).fill("").map((_, i) => (
                                <span
                                    key={i}
                                    className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                        activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                                    }`}
                                    onClick={() => setActiveIndex(i)}
                                />
                            ))}
                        </div>
                    )}
                >
                    {dioceseNewsFuther && dioceseNewsFuther.map((newsItem, index) => {
                        return <div key={index}>
                            <DioceseNewsBlock key={newsItem.id} data={newsItem}/>
                        </div>
                    })}
                </Carousel>
            </div>
            {/* Mobile layout: end */}
        </div>
    );
}
